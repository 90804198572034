.main-footer {
  width: 100%;
  background-color: #141420;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1) !important;
  /* margin-top: 5rem; */
  /* position: fixed; */
  /* bottom: 44px; */
}

.logo2 {
  width: 220px;
  height: 100px;
  object-fit: contain;
}
a {
  text-decoration: none;
}

.container1 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 2rem;
  justify-content: space-evenly;
}

.row {
  width: 200px;
}

.row_content_item {
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
}

.row_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.row_title {
  color: WHITE;
  font-size: 1.1rem;
  font-weight: 400;
  display: flex;
}

.bottom_foot {
  width: 100%;
  background-color: #08070769;
  /* position: fixed;
    bottom: 0; */

  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1) !important;
}

.rights_div {
  padding: 15px 10px;
}

.rights_div {
  color: white;
  font-size: 0.7rem;
}
.socialmedia {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

@media screen and (max-width: 500px) {
  .row_content {
    align-items: center;
  }
  .row_title {
    justify-content: center;
  }
}
