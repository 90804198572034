.track_order_status_container_main {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;
}

.track_order_status_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: initial;
  width: 80%;
}

.track_status {
  font-weight: bold;
}

.track_order_numb {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 20px;
}

.order_details {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.32px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
}

.order_details_data {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.order_details_div1 {
  width: 75%;
}

.order_details_table_header {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #141420;
}

.order_details_table_data {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #141420;
}

.order_details_table>tr>td:first-child {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.track_order_img {
  width: 70px;
  height: 70px;
}

.product_details {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #333333;
  text-align: left;
}

.order_details_table>tr {
  padding: 10px
}

.order_details_table>tr>td {
  width: 16.7%;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}

.order_details_table>tr:first-child>td {
  border-bottom: none;
  padding-bottom: 10px;
}

.order_details_table {
  padding-top: 30px;
  padding-bottom: 30px;
}

.order_details_div2 {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  width: 25%;
  margin: 30px;
  padding: 20px;
}

.cart_total_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #333333;
}

.cart_total_subtitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #141420;
  padding-top: 10px;
}

.cart_total_number {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #333333;
}

.address_div {
  display: flex;
  flex-direction: column;
  width: 25%;

  padding-top: 10px;
}

.address_div_titles {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17.32px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 10px;
}

@media (max-width: 800px) {
  .order_details_data {
    flex-direction: column;
  }

  .order_details_div1 {
    width: 100%;
  }

  .order_details_div2 {
    width: 100%;
    margin: 0px;
  }

  .address_div {
    width: 50%;
  }
}