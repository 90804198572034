.track_order_container_main {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;
}

.track_order_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: initial;
  width: 36%;
}

.track_order_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  color: #141420;
}

.input_label {
  font-family: "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact_support {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  text-align: center;
  padding-top: 30px;
}

.track_order_container_main .login_btn {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30.94px;
  background: #000000;
  border: 0.847134px solid #000000;
  color: white;
  text-transform: uppercase;
  margin-top: 30px;
}

@media (max-width: 700px) {
  .track_order_container {
    width: 80%;
  }
}