.gift-title {
  text-align: left;
  margin-left: 20px;
}

.special_instructions {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 100px;
  padding-left: 15px;
  margin-bottom: 5px;
  margin-left: 2rem;
  width: 50vw;
}

.gift-in {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  justify-content: center;
}

.gift-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5rem;
}

.columndiv {
  display: flex;
  flex-direction: column;
}