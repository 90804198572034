.watchcardfavorite {
  width: 70%;

  display: flex;
  flex-direction: row;
  border: solid #cacaca 2px;
  padding: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pocfav {
  max-width: 13rem;
  max-height: 13rem;
}

.pocfav1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.watchcardfavorite__info {
  text-align: start;
  width: 70%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* justify-content: center;
  align-items: center; */
}

.watchcardfavorite__info__name {
  color: #141420;
  font-family: sans-serif;
  font-size: 20px;
  line-height: 42px;
}

/* test */
.watchcardfavorite__info__brand {
  color: #333333;
  font-family: sans-serif;
}

.watchcardfavorite__info__price {
  color: #333333;
  font-family: sans-serif;
  font-size: 29.68px;
}

.watchcardfavorite__info__brand1 {
  cursor: pointer;
}

@media (max-width: 1143px) {
  .watchcardfavorite__info {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}