.product {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
}

.rout {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.line {
  border-bottom: 1px solid black;
}

.filter-row {
  justify-content: flex-end;
  margin-right: 2rem;
}

.titleWatch {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 41px;
  line-height: 50px;
  padding-left: 1rem;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #141420;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10rem;
}

@media screen and (max-width: 550px) {
  .Main_filters_card212 {
    top: 29rem;
  }

  .titleWatch {
    font-size: 1.5rem;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 450px) {
  .Main_filters_card212 {
    top: 30rem;
  }
}