.watches_info_container {
  width: 80%;
  margin: auto;
}

.file_path_container {
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.path_disabled_tab {
  height: 15px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.3;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.path_current_tab {
  height: 15px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  flex: none;
  order: 4;
  flex-grow: 0;
}

.path_divider {
  height: 15px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.3;
  padding-right: 10px;
  padding-left: 10px;
}

.watch_infos_div {
  display: flex;
  flex-direction: row;
}

.small_watches {
  display: flex;
  flex-direction: column;
}

.watch_small_img {
  width: 71px;
  height: 71px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.watch_image_div {
  width: 40%;
  display: flex;
  flex-direction: row;
}

.watch_infos_details {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.watch_icon {
  width: 30%;
  height: 33.12px;
}

.watch_infos_details_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 51px;
  text-transform: uppercase;
  color: #000000;
  text-align: initial;
}

.watch_code {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  text-align: initial;
}

.swatch_details {
  text-align: initial;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
  padding-top: 20px;
}

.buttons_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  padding-top: 20px;
}

.add_to_chart_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8.47134px 25.414px;
  width: 42%;
  height: 30.94px;
  background: #000000;
  border: 0.847134px solid #000000;
  color: white;
  text-transform: uppercase;
}

.send_inquery_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8.47134px 25.414px;
  width: 42%;
  height: 30.94px;
  background: white;
  border: 0.847134px solid #000000;
  color: #000000;
  text-transform: uppercase;
}

.buy_div {
  display: flex;
  flex-direction: row;
  padding-bottom: 70px;
  margin-top: 2rem;
  justify-content: center;
}

.horizontal_divider {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.2;
  border: 1px solid #000000;
}

.vertical_divider_div {
  width: 20%;
  display: flex;
  justify-content: center;
}

.vertical_divider {
  height: 200px;
  opacity: 0.2;
  width: 0px;
  border: 1px solid #000000;
}

.horizontal_divider_div {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.buy_div_container {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy_div_content_1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.32px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
}

.buy_div_content_2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  opacity: 0.8;
  padding-bottom: 20px;
  padding: 11px;
  margin-bottom: 1rem;

}

.heading_titles {
  padding-top: 10px;
}

.heading_title_1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.31em;
  text-transform: uppercase;
  color: #141420;
}

.heading_title_2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 41px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #141420;
  margin-top: 5rem;
}

.small_divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.small_divider .horizontal_divider {
  width: 10%;
}

.watching_info_display {
  width: 100%;
  margin: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.view_btn {
  padding: 8.47134px 25.414px;
  width: 200px;
  height: 30.94px;
  background: white;
  border: 0.847134px solid #000000;
  color: #000000;
  text-transform: uppercase;
}

.view_btn_container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (max-width: 1264px) {
  .watch_infos_div {
    flex-direction: column;
  }

  .watch_image_div {
    width: 100%;
  }

  .watch_infos_details {
    width: 100%;
    margin: 0px;
  }

  .buy_div {
    flex-direction: column;
  }

  .buy_div_container {
    width: 100%;
  }

  .vertical_divider_div {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .vertical_divider {
    height: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .heading_title_2 {
    font-size: 6vw;
  }
}