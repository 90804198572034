.container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  align-items: center;
}
.favorite-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
