.filter-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.filter_arrow {
  display: flex;
  align-items: center;
}

.arrow_down {
  transform: rotate(180deg);
}

.Main_filter {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.Main_filters_card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.5) !important;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 7%;
  white-space: nowrap;
  display: flex;
  text-align: initial;
  flex-direction: column;
  width: fit-content;
  z-index: 10005;
}

.designFix {
  transform: translate(-1rem, 1rem);
}

.Main_filters_card1 {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.5) !important;
  padding: 10px;
  position: absolute;
  top: 2rem;
  right: 4rem;
  white-space: nowrap;
  display: flex;
  text-align: initial;
  flex-direction: column;
  width: fit-content;
  z-index: 10005;
}

.dropdown-gender {
  top: 2rem;
  right: 17.5rem;
}

.Main_filters_card2 {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.5) !important;
  padding: 10px;
  position: absolute;
  top: 49%;
  left: 71%;
  white-space: nowrap;
  display: flex;
  text-align: initial;
  flex-direction: column;
  width: fit-content;
  z-index: 10005;
}

.Main_filters_card212 {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.5) !important;
  padding: 10px;
  position: absolute;
  top: 27rem;
  right: 4rem;
  white-space: nowrap;
  display: flex;
  text-align: initial;
  flex-direction: column;
  width: fit-content;
  z-index: 10005;
}

.filter-section2 {
  display: flex;
  width: 30%;
}

.filter-row {
  display: flex;
  gap: 2rem;
}

.filter-row-custom {
  width: 550px;
}

@media screen and (max-width: 768px) {
  .filter-row-custom {
    width: 100%;
  }

}

.label_check {
  font-size: 1rem;
  font-weight: 500px;
  cursor: pointer;
}

.filters_more_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.4rem 0.2rem;
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.filters_more_button11 {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
}


.title_drop_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  border: 1px solid black;
  height: 10px;
  border-radius: 5px;

}
.trash_cont {
  background-color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.trash_icon{
  width: 20px;
  height: 20px;
  padding-left: 5px;
}

@media screen and (max-width: 550px) {
  .Main_filters_card212 {
    top: 29rem;
  }

  .titleWatch {
    font-size: 1.5rem;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 450px) {
  .Main_filters_card212 {
    top: 30rem;
  }
}