.rout {
  display: flex;
  flex-direction: row;
  margin-left: 10rem;
  margin-top: 3rem;
}

.homeroute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.3;
}

.watchroute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.3;
}

.productroute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 10rem;
  gap: 2rem;
}

.image {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}

.imagescoloumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.smallimage {
  max-width: 4rem;
}

.smallimage :hover {
  border: 2px solid blue;
}

.image-conto {
  width: 25rem;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
}

.description img {
  height: 50px;
}

@media (max-width: 1404px) {
  .description {
    width: 30%;
  }
}

@media (max-width: 1005px) {
  .description {
    width: 100%;
    margin-top: 4rem;
  }
}

.watchname {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 51px;
  text-transform: uppercase;

  color: #000000;
  width: 100%;
  text-align: start;
  margin-bottom: 1rem;
}

.brandpic {
  margin-bottom: 2rem;
  height: 4rem;
  width: 16rem;
}

.code {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #000000;
}

.cont1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 2rem;
  margin-left: 1rem;
}

.soldby {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8.12px;
  line-height: 10px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.partner {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.1176px;
  line-height: 14px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.descriptiontext {
  margin-top: 2rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;

  color: #000000;

  opacity: 0.8;
}

.threematerial {
  display: flex;
  flex-direction: row;
}

.addcart {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 113% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: #000000;
  border: 0.847134px solid #000000;

  color: #ffffff;
  margin-right: 1rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.inquiry {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 113% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #000000;
  margin-right: 2rem;
  padding: 0.5rem;
  background-color: #ffffff;
}

.threematerial {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  /* Black */

  color: #000000;
  margin-bottom: 1rem;
}

.group {
  display: flex;
  flex-direction: row;
  padding-right: 2rem;
  gap: 2rem;
}

.eachone {
  display: flex;
  flex-direction: column;

  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  /* identical to box height, or 204% */

  letter-spacing: 0.5px;

  color: #757575;
}

.downPosition {
  margin-top: 0.5rem;
}

.details {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 135% */

  letter-spacing: 0.5px;

  color: #101010;
  padding-bottom: 1rem;
  text-align: start;
}

.red {
  color: red;
}

.SecondContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
}

.heart {
  width: 20.9px;
  height: 18.23px;
}

.titlecont {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.32px;
  line-height: 14px;
  /* identical to box height, or 78% */

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.titlecont2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  text-align: center;

  color: #000000;

  opacity: 0.8;
}

.button {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 113% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #000000;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}

.second {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

@media (max-width: 671px) {
  .container {
    margin-left: 2rem;
  }

  .rout {
    margin-left: 2rem;
  }
}

@media (max-width: 437px) {
  .image-conto {
    width: 17rem;
  }
}

.strong::first-letter {
  text-transform: uppercase;

}

.strong {
  margin-right: 1rem;
}