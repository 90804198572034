.Main_mobile_header {
    transition: all 2s ease-in-out;
    height: 100%;
    width: 20rem;
    background: #f8f8f8;
    position: fixed;
    z-index: 22;
    top: 0;
    right: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
    overflow: auto;
}

.icon-mobile {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
}

.mobile_header_image {
    background: black;

    border-radius: 8px;
    padding: 25px 0 25px 0;
}

.mobile_header_logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mobile_header_cont {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mobile_header_close {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: #000;
}


.favorite-count {
    position: relative;
    background-color: red;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 11px;
    width: 1px;
    justify-content: center;
    display: flex;
    height: 9px;
    color: white;
    align-items: center;
    /* border: 0px solid black; */
    margin-left: 1rem;
    margin-top: -18px;
}

.mobile_header_icons {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 1rem;
}

.mobile_header_links {
    margin-top: 1rem;
}

.header_icons_container1 {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 2rem;
    color: #000;
}

.mobile_header_link {
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    line-height: 3rem;
}

.close_butt {
    color: #000;
}

.header-icons-buger {
    color: #000;
}

.search-bar-burger {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 5px;
    color: black;
    transform: translateX(0.4rem);
    width: 100%;
    padding: 0.2rem 0.2rem;

}

.header_icon-burger {
    color: #000;
    padding-left: 1rem;
}