.terms_and_conditions_main_container {
  width: 85%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
}

.terms_and_conditions_tite {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 54px;
  text-transform: uppercase;
  color: #000000;
}

.condition {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.conditiontime {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 17px;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .terms_and_conditions_tite {
    font-size: 7vw;
  }

}