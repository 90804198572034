.apply_form_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
}

.apply_form_row_col {
  width: 46%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.apply_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
}

.apply_form_labels {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
  margin-top: 1rem;
}

.apply_input {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  width: 95%;
  height: 50px;
  padding-left: 15px;
  border: #141420 solid 1px;
}

.apply_main_div {
  width: 90%;
}

.apply_form_row_col1 {
  width: 100%;
  padding-top: 33px;
  margin-right: 30px;
}

.apply_form_row_submit_btn {
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.submit_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;

  height: 39px;
  background: #ffffff;
  border: 1 solid #c8c8c8;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  width: max-content;
}

@media Screen and (max-width: 660px) {
  .apply_form_row {
    flex-direction: column;
  }

  .apply_form_row_col {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }
}