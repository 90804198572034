.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 8px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.popup_icon {
  display: flex;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 1rem;
}

.body_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 80%;
  }
}