.Main_Products {
  width: 85%;
  margin: auto;
  margin-top: 2rem;
}


.Products_header {
  display: flex;
}

.section_route {
  opacity: 0.7;
}

.section_name {
  opacity: 1;
  font-weight: 500;
  margin-left: 4px;
}

.Products_container_filters {
  margin-top: 4rem;
}

.filter_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2rem;
}

.Products_container_products {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.Products_container {
  width: 95%;
  margin: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width:650px) {
  .Products_container{
    justify-content: center !important;
  }
}

.Products_container_pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.pagination_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4em;
}

.pagination_container_item {
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.5;
}

.pagination_container_item:hover {
  opacity: 1;
}

.pagination_container_item_active {
  font-size: 1.2rem;
  font-weight: 500;

  opacity: 1;
}

.filter_conts {
  width: 50%;
}

.filter-row-cutom .filter-item {
  padding-left: 10%;

}

.Main_filters_card212 {
  left: 10%;
}


.blackspace {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 99;

}

@media (max-width: 1000px) {
  .Main_filters_card212 {
    top: 63% !important;
  }

}

@media (max-width: 500px) {

  .filter_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .filter-row-cutom {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 2rem;
    justify-content: flex-start;

  }

  .filter_container {
    display: flex;
    flex-direction: column;
  }

  .Main_filter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .watching_info_display {
    width: 80%;
  }

  .filter-section2 {
    display: flex;
    flex-direction: column;
  }

  .Main_filters_card212 {
    top: 90% !important;
  }

  /* .filter_container_title{
  width: 30%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  } */
}
.filter_container_title,.filters_more_button{
  white-space: nowrap;
}

.Main_filter.more-filters-clicked .Products_container {
  width: 72%;
}