.starter {
  display: flex;
  flex-direction: column;

  height: 286px;

  background: #141420;
  margin-bottom: 3rem;
}

.upper {
  height: 214px;
  display: flex;
  flex-direction: column;
}

.down {
  height: 72px;
  background: rgba(249, 249, 249, 0.04);
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 564px) {
  .down {
    gap: 1rem;
    padding: 1rem 0;
  }
}

.homerouting {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff;

  opacity: 0.3;
  cursor: pointer;
}

.Proute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff;
}

.Pro {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 41px;
  line-height: 50px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  text-align: center;
}

.cat {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  cursor: pointer;
}

.filter-item {
  position: relative;
}

.Main_filters_card2 {
  position: absolute;
  top: 2rem;
  left: 0;
}