.click_and_collect_main_container {
  width: 85%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.click_and_collect_main_tite {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  color: #141420;
}

.click_and_collect_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.click_and_collect_labels {
  font-family: sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000e7 !important;
  padding-bottom: 15px;
  padding-top: 17px;
  margin-top: 2rem;
}

.click_and_collect_input {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  width: 96%;
  height: 50px;
  padding-left: 15px;
}

.click_and_collect_select {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  margin-bottom: 1rem;
  margin-top: 1rem;

}

.click_and_collect_textarea {
  height: 150px;
  padding-top: 15px;
}

.click_and_collect_submit_btn {
  width: 20%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: 20px;
}

@media (max-width: 942px) {
  .click_and_collect_submit_btn {
    width: 50%;
  }

  .click_and_collect_input {
    height: 39px;
  }
}

@media (max-width: 600px) {
  .click_and_collect_labels {
    font-size: 15px;
  }
}