.Main_profile_div {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: initial;
    margin-top: 4rem;
    height: auto;
}

.Main_profile_div .modal {
    padding-top: 50px;
}

.Profile_div_header_text {
    font-size: 2.5rem;
    font-weight: 600;

}

.Profile_header_info_container {
    margin-top: 4rem;
}

.Profile_header_info_container_text {
    font-size: 1.5rem;
    font-weight: 500;

}

.header_texts_tit {
    font-size: 1.2rem;
    font-weight: 500;
}

.header_texts {
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    font-size: 1.1rem;
}

.Profile_header_info_container_all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 3rem;
}

.header_button_section {
    display: flex;
    margin-top: 3rem;
    flex-direction: row;
    gap: 2rem;
}

@media screen and (max-width: 768px) {
    .header_button_section {
        flex-direction: column;
        /* Change the flex direction for smaller screens */
        gap: 1rem;
        /* Adjust the gap for better spacing */
    }

    .header_texts {
        font-size: 13px;
    }

    .header_texts_tit {
        font-size: 20px;

    }
}

.button_1 {
    cursor: pointer;
    width: 200px;
    height: 40px;

    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    border: 3px solid #000000;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: transparent;
    color: #000000;

}

.button_2 {
    width: 200px;
    height: 40px;
    cursor: pointer;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    border: 3px solid #000000;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: #000000;
    color: white;
}

.underline_profile {
    width: 100%;


    height: 0px;
    margin-top: 4rem;
    opacity: 0.2;
    border: 1px solid #000000;

}

.profile_body {
    margin-top: 4rem;
}

.order_card {
    margin-top: 4rem;
}

.pre_orders_img {
    width: 100px;
    height: 120px;
    object-fit: cover;
    margin-right: 6px;

}

.head_cont {
    display: flex;
}

.prev_order_numbers {
    font-size: 1rem;
    font-weight: 500;

}

.order_num {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;


}

.profile_order_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4rem;
}

.Profile_header_info_container_text {
    margin-top: 4rem;
}

.billing_cont {
    margin-top: 2rem;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: row;
    gap: 4rem;
}

.title_billing {
    font-size: 1.5rem;
    font-weight: 500;

}

.billing_info_text {
    margin-top: 1rem;
    width: 200px;
}

.bill_cont {
    margin-top: 2rem;
}

.logout_div {
    margin-bottom: 4rem;
    cursor: pointer;
}

.logout_text {
    font-weight: 600;
}

.popup_profile_edit_main_tite {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
    color: #141420;
}

.popup_profile_edit_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.popup_profile_edit_labels {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
    padding-bottom: 15px;
    padding-top: 15px;
}

.popup_profile_edit_input {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #2f2f2f;
    width: 96%;
    height: 45px;
    padding-left: 15px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.popup_profile_edit_btn {
    width: 35%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 39px;
    background: #000000;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
}

.gender_div {
    display: flex;
    flex-direction: row;
}

.gender_container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.form-edit {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

}

@media screen and (max-width: 768px) {
    .Main_profile_div {
        width: 90%;
    }

}