textarea {
  resize: vertical;
  max-height: 450px;
}

.header-wrapper {
  position: relative;
  height: 410px;
}

.contact_us_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.contact_us_text {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 430px;
  text-align: left;
  /* background: linear-gradient(rgba(0, 0, 0, 2), rgba(0, 0, 0, 0)); */
  padding: 10px;
  border-left: 0px;
  justify-content: flex-start;
}

.contact_us_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 2px 2px 8px black;
}

.help_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 54px;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 2px 2px 8px black;

}

.help_details {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.8;
  text-shadow: 6px 6px 8px black;

}


.contact_us_form {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 40%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 999999999;
}

.contact_us_content {
  margin: 10px;
  padding: 10px;
}

.contact_us_labels {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
  padding-top: 15px;
}

.spaceing-label {
  padding-left: 0.8rem;
}

.contact_us_input {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  width: 93%;
  height: 50px;
  padding-left: 15px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.customPadding {
  transform: translateX(0.3rem);
}

.contact_us_content_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}

.input_width {
  width: 95%;
}

.contact_us_phone_row,
.contact_us_email_row {
  width: 50%;
}

.contact_us_textarea {
  height: 100px;
}

.contact_us_submit_btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.reach_us_container {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 40px;
  text-align: left;
}

@media screen and (max-width: 786px) {
  .reach_us_container {
    margin: 0.5rem;
    width: 100%;
  }

}

.reach_us_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.32px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
}

.reach_us_container_row {
  display: flex;
  flex-direction: row;
}

.reach_us_container_col {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
}

.map_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  position: relative;
}

.stores_div_container {
  width: 300px;
  padding: 30px;
  background-color: #2f2f2f;
  z-index: 20;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 4rem;


}

.boder_div {
  width: 20px;
  height: 20px;
  border-left: 5px solid #00ceff96;
  /* Left border color and width */
  border-bottom: 5px solid #00ceff96;
  /* Bottom border color and width */
  margin-right: 13px;
}

.title_container {
  display: flex;
  flex-direction: row;
}

.stores_div_title {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding-bottom: 10px;
}

.map_stores_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.stores_div {
  max-height: 300px;
  /* Set the maximum height for the scrollable area */
  overflow-y: auto;
  width: 300px;
  background-color: black;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

/* Style for WebKit browsers (Chrome, Safari) */
.stores_div::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.stores_div::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Background color of the track */
}

.stores_div::-webkit-scrollbar-thumb {
  background-color: rgb(38, 59, 111);
  /* Color of the scrollbar thumb */
  border-radius: 5px;
  /* Rounded corners */
}

.stores_div::-webkit-scrollbar-thumb:hover {
  /* background-color: rgb(36, 161, 211); */
  background-color: rgb(38, 59, 111);
  /* Color of the thumb on hover */
}

.store_country {
  color: #ffffff;
}

.store_country:hover,
.map_store_country:hover {
  cursor: pointer;
}

.map_store_country {
  color: #000000;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
}

.store_data {
  color: darkgray;
}

.map_store_data {
  color: black;
  margin-bottom: 6px;
}

.store_box {
  border-bottom: 1px solid darkgray;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.time_div {
  margin-bottom: 10px;
}

.view_on_google_map {
  color: rgb(152, 78, 212);
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 10px;
}

.view_on_google_map:hover {
  cursor: pointer;
}

.view_on_map {
  color: rgb(38, 59, 111);
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 10px;
}

.view_on_map:hover {
  color: #ffffff;
  cursor: pointer;
}

@media Screen and (max-width: 1098px) {
  .contact_us_form {
    top: 46%;
  }
}

/* @media Screen and (max-width: 1109px) {
  .contact_us_text {
    top: 68%;
    left: 50%;
  }

  .contact_us_text {
    top: 68%;
    left: 29%;
  }
} */

@media Screen and (max-width: 1076px) {
  .contact_us_form {
    position: absolute;
    top: 51%;
  }

  /* .contact_us_text {
    left: 29%;
  } */
}

@media Screen and (max-width: 956px) {
  /* .contact_us_text {
    left: 25%;
  } */

  .contact_us_form {
    left: 58%;
  }
}

@media Screen and (max-width: 838px) {
  .contact_us_form {
    position: initial;
    width: 100%;
  }

  .contact_us_text {
    left: 50%;
    width: 90%;
  }

  .contact_us_text .help_title {
    font-size: 2.4rem;
  }
}

@media Screen and (max-width: 600px) {
  /* .contact_us_text {
    top: 85%;
  } */

  .stores_div_container {
    position: absolute;
    margin-top: 1rem;
    width: fit-content;
    margin: 1rem;
  }

  .stores_div {
    width: 100%;
  }
}

@media Screen and (max-width: 500px) {
  /* .contact_us_text {
    top: 85%;
    left: 50%;
    width: 90%;
  } */

  .stores_div {
    width: 100%;
  }

  .stores_div_container {
    left: -2rem;
  }
}

@media Screen and (max-width: 350px) {
  /* .contact_us_text {
    top: 95%;
  } */

  .stores_div_container {
    left: -2rem;
  }

}


@media (max-width: 768px) {
  .reach_us_container_row {

    flex-direction: column;
  }
}